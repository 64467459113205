.header{
  padding: .6em 2em;
  background-color: #F0EDE6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.links{
  font-family: 'Lato', sans-serif;
  display: flex;
  gap: 1em;
  align-items: center;
  text-decoration: none;
}

.link{
  font-size:12pt;
  font-weight:600;
  text-decoration: none;
  color: rgb(35, 37, 43);  
}

.menu-btn{
  display: none !important;
}

@media (max-width: 513px) {
  .links{
    display: none;   
  }
  .menu-btn{
    display: block !important;
  }
}