.hero{
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media(max-width: 700px) {
  body {
    /* background-color:red; */
  }
  .hero{
    flex-direction: column;
  }
}
