.technologies{
  padding: 4em 2em;
  display: flex;
}

.info{
  /* background-color: yellow; */
  width: 60%;
}

.visual{
  position: relative;
  width: 40%;
  overflow: hidden;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.visual img{
  width: 100%;
  max-width: 380px;
  animation: pulse 5s infinite;
}

.sub-text{
  color: #4B4E59;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size:18px;
}

.main-title{
  font-family: 'Kanit', sans-serif;
  font-size: 22pt;
  line-height: 1em;
  position: relative;
  margin-bottom: 1.5em;
  color: #23252B;
}

.main-title::after{
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 120px;
  height: 10px;
  background-color: #327458;
}

.icons{
  margin-top: 1em;
  margin-bottom: 2.5em;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  gap: 1em 2em ;
  flex-wrap: wrap;
}

.icons img{
  width: 9vmin;
  max-width: 40px;
}

.stack{
  width: 60vmin;
  max-width: 250px;
  margin-top: 1em;
}

.decoration{
  width: 100%;
  height: 40%;
  background-color: #E69C37;
  position: absolute;
  z-index: -1;
  border-radius: 1.5em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 400px) {
  .icons{
    width: 100%;
  }
}

@media (max-width: 600px) {
  .visual{
    display: none;
  }
  .info{
    width: 100%;
  }
}

@media (min-width: 898px) {
  .visual img{
    max-width: 280px;
  }

}

@keyframes pulse {
  0% {
    transform: translate(0, 10%)
  }
  50%{
    transform: translate(0, 0%)
  }
  100% {
    transform: translate(0, 10%)
  }
}